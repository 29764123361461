@import url("https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,400;0,700;1,700&family=Nunito:wght@200;300;400;500;600;700;800;900&display=swap");

:root {
  --font-logo: "Roman", serif;
  --font-body: "Nunito", sans-serif;
  --font-title: "Noto Serif", serif;
  --color-primary: rgb(40, 175, 176);
  --color-primary-dark: #219292;
  --color-secondary: #ddcecd;
  --color-tertiary: #eff2f1;
  --color-gray: rgb(79, 90, 107);
  --color-gray-alpha: rgba(79, 90, 107, 0.8);
  --color-background: white; /* #fcfcfc */
  --default-border-radius: 5px;
}

.container {
  padding: 1em;
}

.title {
  font-family: var(--font-title);
  font-size: 2.2em;
  font-weight: 400;
  text-align: center;
  margin: 0;
}
