.active {
  color: var(--color-primary);
  border-bottom: 1px solid var(--color-primary);
}

.error {
  color: red;
  bottom: 0;
  left: 0;
  margin-top: 0.5em;
  white-space: pre;
}
