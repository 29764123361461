:root {
  --font-logo: "Roman", serif;
  --font-body: "Nunito", sans-serif;
  --font-title: "Noto Serif", serif;
  --color-primary: rgb(40, 175, 176);
  --color-primary-dark: rgb(33, 146, 146);
  --color-secondary: #ddcecd;
  --color-tertiary: rgb(239, 242, 241);
  --color-gray: rgb(79, 90, 107);
  --color-gray-alpha: rgba(79, 90, 107, 0.8);
  --color-background: white; /* #fcfcfc */
  --default-border-radius: 5px;
}

/* Buttons */
.button {
  border-radius: var(--default-border-radius);
  border: 1px solid var(--color-gray);
  color: var(--color-gray);
  padding: 0.7em 1.2em;
  margin: 0 0.3em;
  font-weight: 700;
  letter-spacing: 0.6px;
  background-color: transparent;
}

.button:hover {
  color: white;
  background-color: var(--color-secondary);
  border-color: var(--color-secondary);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.buttonPrimary {
  height: 3em;
  width: 10em;
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  box-shadow: 0 3px 5px rgba(41, 41, 41, 0.3);
  color: white;
}

.buttonPrimary:hover {
  color: white;
  background-color: var(--color-primary-dark);
}

.buttonPrimary:disabled {
  background-color: var(--color-gray-alpha);
  border-color: var(--color-gray);
  cursor: not-allowed;
}

/* Modal */
.modal {
  /* padding-top: 50px; */
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* overflow: auto; */
  background-color: var(--color-gray-alpha);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContent {
  background-color: var(--color-background);
  /* margin: 5% auto 15% auto; */
  height: 95vh;
  width: 95vw;
  border-radius: var(--default-border-radius);
  position: relative;
  box-shadow: 0 1px 3px rgba(34, 25, 25, 0.5);
  overflow: hidden;
}

.close {
  height: 70px;
  width: 70px;
  font-size: 2.5em;
  border: none;
  border-right: 1px solid var(--color-gray);
  border-bottom: 1px solid var(--color-gray);
  background-color: var(--color-background);
  transition: all 0.2s ease-out;
  cursor: pointer;
}

.close:hover {
  font-size: 3em;
  background-color: var(--color-tertiary);
  color: red;
}

.title {
  font-size: 4em;
  text-align: center;
  margin-top: 60px;
}

/* Interests */
.sportName {
  font-size: 1.4em;
}

.checkbox {
  margin-right: 5px;
}

.interestBox {
  width: 150px;
  display: flex;
  align-items: center;
}

.interests {
  width: 60%;
  display: flex;
  justify-content: left;
  align-items: center;
  flex-flow: row wrap;
  gap: 3em;
}

.interestContainer {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  gap: 7em;
}

/* Navigation */
.navigation {
  height: 70px;
  display: flex;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.6);
}

/* Status Bar */
.statusBar {
  width: 100%;
  display: flex;
  /* flex: 1 1 0; */
  flex-grow: 1;
  flex-flow: row nowrap;
  justify-content: space-around;
  position: relative;
}

.statusSection {
  /* height: 70px; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-right: 1px solid var(--color-gray);
  background-color: var(--color-background);
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.statusSection:last-of-type {
  border: none;
}

.statusSection:hover {
  background-color: var(--color-tertiary);
}

.statusSection:disabled {
  color: black;
  cursor: not-allowed;
}

.sectionText {
  width: 105px;
}

.sectionTitle {
  font-size: 0.8em;
  font-weight: 500;
  color: var(--color-gray);
  text-align: left;
  text-transform: uppercase;
}

.sectionIcon {
  color: var(--color-gray);
  font-size: 2em;
  margin-right: 8px;
}

.sectionSelection {
  text-align: left;
  font-weight: 800;
}

.payment form {
  display: flex;
  flex-flow: column nowrap;
  gap: 5px;
  margin-top: 30px;
}

.payment input,
.payment select {
  height: 45px;
  border: 1px solid var(--color-gray);
  background-color: white;
  margin-right: 25px;
  padding: 0 15px;
  flex-grow: 1;
}

.payment ::placeholder {
  color: var(--color-gray);
}

.payment form > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  gap: 2em;
}

p {
  font-size: 30px;
}

.profileIcon {
  height: 85px;
  width: 85px;
  border-radius: 100%;
  background-color: var(--color-gray);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: 0 auto;
}

.profileIcon > img {
  border-width: 0 !important;
  color: white;
  font-size: 2em;
  font-weight: 800;
  letter-spacing: 1px;
}
