.promoBlue {
  background-color: #3d348b;
}

.promoPurple {
  background-color: #533a71;
}

.promoBrown {
  background-color: #877b66;
}

.promoMaroon {
  background-color: #b33951;
}

.promoPine {
  background-color: #539987;
}

.promoGrey {
  background-color: #36413e;
}

.promoAuburn {
  background-color: #a22522;
}
