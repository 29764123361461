/* Buttons */
.button {
  border-radius: var(--default-border-radius);
  border: 1px solid var(--color-gray);
  color: var(--color-gray);
  padding: 0.7em 1.2em;
  margin: 0 0.3em;
  font-weight: 700;
  letter-spacing: 0.6px;
  background-color: transparent;
}

.button:hover {
  color: white;
  background-color: var(--color-secondary);
  border-color: var(--color-secondary);
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.buttonPrimary {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  box-shadow: 0 3px 5px rgba(41, 41, 41, 0.3);
  color: white;
}

.buttonPrimary:hover {
  color: white;
  background-color: var(--color-primary-dark);
}

/* Modal */
.modal {
  padding-top: 50px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-gray-alpha);
  z-index: 999;
}

.modal input[type="text"],
.modal input[type="password"] {
  width: 100%;
  height: 25px;
  border: none;
  border-radius: var(--default-border-radius);
  background-color: var(--color-tertiary);
  padding: 0.2em 0.5em;
}

.inputContainer {
  position: relative;
  margin: 0.3em 0 1.5em 0;
}

.modal label {
  font-weight: 600;
}

.modal label > input {
  width: auto;
}

.modal .name {
  width: 50%;
}

.modal .name > input {
  width: 90%;
}

.nameContainer {
  display: flex;
  width: 475px;
}

.buttonSubmit {
  display: block;
  margin: 0;
  width: 103.5%;
}

.modalContent {
  background-color: var(--color-background);
  margin: 5% auto 15% auto;
  width: 500px;
  border-radius: var(--default-border-radius);
  position: relative;
  box-shadow: 0 1px 3px rgba(34, 25, 25, 0.5);
}

.modalContainer {
  padding: 1em;
  overflow: hidden;
}

.passwordContainer {
  position: relative;
}

.passwordContainer > button {
  height: 31px;
  width: 31px;
  border: none;
  border-radius: var(--default-border-radius);
  background-color: transparent;
  position: absolute;
  top: 0;
  right: -15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.passwordContainer > button:hover {
  background-color: #c8c9c8;
}

.close {
  height: 0.7em;
  width: 0.7em;
  position: absolute;
  top: 0;
  right: -45px;
  font-size: 40px;
  padding: 0.1em;
  line-height: 0.7;
  text-align: center;
  color: var(--color-background);
  border-radius: var(--default-border-radius);
}

.close:hover {
  cursor: pointer;
  background-color: var(--color-secondary);
  color: red;
}

.tablink {
  margin-right: 0.3em;
  border-radius: 0%;
  background-color: transparent;
  border: 0;
  font-size: 1.1em;
  font-weight: 600;
}

.tablink:hover {
  color: var(--color-primary-dark);
  cursor: pointer;
}

.tablinkContainer {
  margin-top: 0.2em;
  border-bottom: 1px solid var(--color-tertiary);
}

.active {
  color: var(--color-primary);
  border-bottom: 1px solid var(--color-primary);
}

.tab {
  padding: 1em 1em 1em 0;
}

.thirdParty {
  display: flex;
  justify-content: center;
  margin: 0.2em 0 1em 0;
  padding-bottom: 2em;
  border-bottom: 1px solid var(--color-tertiary);
}

/* Animations */
.slideInRight {
  transform: translateX(100%);
  animation: slide-in 0.3s ease-out forwards;
}

.slideInLeft {
  transform: translateX(-100%);
  animation: slide-in 0.3s ease-out forwards;
}

@keyframes slide-in {
  to {
    transform: translateX(0);
  }
}
