:root {
  --font-logo: "Roman", serif;
  --font-body: "Nunito", sans-serif;
  --font-title: "Noto Serif", serif;
  --color-primary: rgb(40, 175, 176);
  --color-primary-dark: rgb(33, 146, 146);
  --color-secondary: #ddcecd;
  --color-tertiary: rgb(239, 242, 241);
  --color-gray: rgb(79, 90, 107);
  --color-gray-alpha: rgba(79, 90, 107, 0.8);
  --color-background: white; /* #fcfcfc */
  --default-border-radius: 5px;
}

.counterContainer {
  height: 50px;
  width: 500px;
  font-size: 1.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  background-color: var(--color-tertiary);
  padding: 1.5em;
  margin-bottom: 5px;
}

.label {
  width: 150px;
  text-align: left;
}

.counter {
  width: 75px;
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.counter div {
  width: 30px;
}

.counter button {
  font-size: 0.7em;
  height: 30px;
  width: 30px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  transition: all 0.2s ease-in-out;
  background-color: #c0c0c0;
}

.counter button:hover {
  color: white;
  background-color: var(--color-gray);
}

.price {
  width: 95px;
}
