:root {
  --font-logo: "Roman", serif;
  --font-body: "Nunito", sans-serif;
  --font-title: "Noto Serif", serif;
  --color-primary: rgb(41, 176, 176);
  --color-primary-dark: #219292;
  --color-primary-tint: #caefef;
  --color-secondary: #ddcecd;
  --color-tertiary: #eff2f1;
  --color-gray: rgb(79, 90, 107);
  --color-gray-alpha: rgba(79, 90, 107, 0.8);
  --color-red: red;
  --color-background: white; /* #fcfcfc */
  --default-border-radius: 5px;
}

.mic {
  height: 35px;
  width: 35px;
  font-size: 1.15em;
  border-radius: 50%;
  border: none;
  background-color: var(--color-tertiary);
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

.mic:hover {
  background-color: rgb(221, 221, 221);
}

.micActive {
  color: var(--color-red);
}

.micInactive {
  color: var(--color-gray);
}
