/* 
  Athlos - Global Styles
*/
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,400;0,700;1,700&family=Nunito:wght@200;300;400;500;600;700;800;900&display=swap");

:root {
  --font-logo: "Roman", serif;
  --font-body: "Nunito", sans-serif;
  --font-title: "Noto Serif", serif;
  --color-primary: rgb(40, 175, 176);
  --color-primary-dark: #219292;
  --color-secondary: #ddcecd;
  --color-tertiary: #eff2f1;
  --color-gray: rgb(79, 90, 107);
  --color-gray-alpha: rgba(79, 90, 107, 0.8);
  --color-red: red;
  --color-background: white; /* #fcfcfc */
  --default-border-radius: 5px;
}

/* Cards */
.card {
  height: 400px;
  width: 300px;
  border-radius: var(--default-border-radius);
  border: 1px solid var(--color-gray);
  background-color: transparent;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.card:hover {
  background-color: white;
  /* transform: scale(1.01); */
}

.card i {
  color: var(--color-gray);
  font-size: 3em;
  width: 90px;
  height: 90px;
  border: none;
  background-color: var(--color-tertiary);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Animations */
.fadeIn {
  animation: fade-in 3s ease forwards;
}

.loadIn {
  animation: load-in 0.1s ease forwards;
  opacity: 0.8;
}

@keyframes fade-in {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes load-in {
  to {
    opacity: 1;
  }
}
